<template>
    <div class="dropdown-wrapper" :class="{'selected' : isShowDropdown}" >
        <perfect-scrollbar>
            <div class="dropdown-container__wrapper">
                <div class="dropdown-container" v-for="(o,index) in data" :key="index" @click="handleDropdownSelect(o)">
                    <div class="dropdown-container__name">
                         <img class="img-contain mr-05rem"  :src="require(`../../../static/images/flags/${o.icon}.png`)">
                        <span>{{o.name}} (+{{o.callingCode}})</span>
                    </div>
                    <div class="dropdown-container__tick" :class="{'selected' : o.selected}"></div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>  
</template>

<script>
export default {
    props: {
        data: {
            type: Array
        },
    },
    data() {
        return {
             isShowDropdown: false
        }
    },
    mounted() {
          document.addEventListener('click', this.documentClick);
    },
    methods: {
        handleDropdownSelect(o){
            this.data.forEach(x => {
                x.selected = false;
            })
            o.selected = true;
            this.isShowDropdown = false;
            this.$emit('dropDownChange',o);

        },
        handleOpen(e){
            this.isShowDropdown = !this.isShowDropdown;
            e.stopPropagation();
        },
        handlClose(){
            this.isShowDropdown = false;
        },
        documentClick(e){
            this.isShowDropdown = false;
            e.stopPropagation();
          
        }
    },
}
</script>

<style>

</style>