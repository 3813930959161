<template>
    <ForgetPassword></ForgetPassword>
    <Footer v-if="!isMobile"></Footer>
</template>

<script>
  import ForgetPassword from '@/components/user/ForgetPassword.vue'
  import Footer from '@/components/footer/Footer.vue'

import {mapGetters,mapActions} from 'vuex'
  export default {
    components: {
        ForgetPassword,
        Footer
    },
      computed: {
        ...mapGetters([
            'isMobile'
        ]),
    },
  }
</script>

